'use strict';

const
    Emitter = require('cjs-emitter'),
    backend = new Emitter();


module.exports = {
    instance: backend
};
