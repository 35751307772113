'use strict';

/* eslint no-empty-function: 0 */

var deviceApi = require('./device.api'),
    defaults     = {
        method: 'GET',  /* HTTP method to use, such as "GET", "POST", "PUT", "DELETE", etc. */
        async: true,   /* whether or not to perform the operation asynchronously */
        headers: {},     /* list of HTTP request headers */
        data: null,   /* data to send (plain object) */
        timeout: 30000,  /* amount of milliseconds a request can take before being terminated */
        type: 'text',
        onload: function () {},   /* callback when the request has successfully completed */
        onerror: function () {},   /* callback when the request has failed  */
        ontimeout: null    /* callback when the author specified timeout has passed before the request could complete */
    },
    app = require('../../app'),
    defaultsKeys = Object.keys(defaults),
    requestStack = [],
    locked       = false,
    api          = {
        host: null,
        authHost: null,
        lang: 'en',
        session: {
            account: null,
            accessToken: null,
            refreshToken: null,
            userId: null,
            macAlgorithm: null,
            macKey: null
        },
        /* eslint max-lines-per-function: 0  */
        request: function ( url, options, afterRefresh ) {
            var client = fetch(),
                index, headersKeys;


            if ( locked ) {
                requestStack.push({
                    url: url,
                    options: options
                });

                return;
            }

            locked = true;
            options = options || {};

            if ( url && (typeof url === 'string' || url instanceof String) && url.length > 0 ) {
                if ( options && typeof options === 'object' ) {
                    for ( index = 0; index < defaultsKeys.length; index++ ) {
                        // in case not redefined
                        if ( options[defaultsKeys[index]] === undefined ) {
                            options[defaultsKeys[index]] = defaults[defaultsKeys[index]];
                        }
                    }
                }

                client = fetch();
                // api.session.account.address = api.session.account.address.replace('stalker_portal/', '');
                require('./shared.backend').instance.host = api.host;
                api.authHost = api.host + '/stalker_portal/auth/token.php';
                client.open(options.method, api.host + '/stalker_portal/api/v3/' + url, options.async);

                client.setRequestHeader('Authorization', 'Bearer ' + api.session.accessToken);
                client.setRequestHeader('Content-Type', 'application/json');
                client.setRequestHeader('Accept-Language', app.stalker.lang);

                if ( options.headers && typeof options.headers === 'object' ) {
                    headersKeys = Object.keys(options.headers);
                    for ( index = 0; index < headersKeys.length; index++ ) {
                        client.setRequestHeader(headersKeys[index], options.headers[headersKeys[index]]);
                    }
                }

                client.responseType = options.type;
                client.timeout = options.timeout;

                client.onreadystatechange = function onreadystatechange () {
                    var data = false;

                    if ( this.readyState === 0 ) {
                        locked = false;
                        if ( requestStack.length > 0 ) {
                            data = requestStack.shift();
                            app.stalker.request(data.url, data.options);
                        }
                    } else if ( this.readyState === 4 ) {
                        if ( this.status === 0 ) {
                            locked = false;
                            if ( requestStack.length > 0 ) {
                                data = requestStack.shift();
                                app.stalker.request(data.url, data.options);
                            }
                        } else if ( this.status === 200 ) {
                            try {
                                data = JSON.parse(this.responseText);
                            } catch ( error ) {
                                data = false;
                                console.log(error);
                            }

                            try {
                                options.onload(!data, data);
                            } catch ( exception ) {
                                if ( app.stalker.onfail ) {
                                    app.stalker.onfail('Exception thrown ' + exception.message);
                                }
                                if ( options.onerror ) {
                                    try {
                                        options.onerror.call(client);
                                    } catch ( error ) {
                                        console.log(error);
                                    }
                                }
                            }

                            locked = false;
                            if ( requestStack.length > 0 ) {
                                data = requestStack.shift();
                                app.stalker.request(data.url, data.options);
                            }
                        } else if ( this.status === 401 ) {
                            if (
                                app.stalker.session
                                && app.stalker.session.refreshToken
                                && app.stalker.session.account
                                && app.stalker.session.account.activationCode
                            ) {
                                if ( afterRefresh ) {
                                    app.stalker.oncredenitalsrequired(function () {
                                        locked = false;
                                        app.stalker.request(url, options);
                                    });

                                    return;
                                }
                                app.stalker.auth.refreshToken(app.stalker.session.refreshToken, function ( error ) {
                                    if ( error ) {
                                        if ( error.error === 'invalid_grant' ) {
                                            app.stalker.oncredenitalsrequired(function () {
                                                locked = false;
                                                app.stalker.request(url, options);
                                            });
                                        } else if ( error.error === 'access_denied' && error.error_description === 'Account disabled') {
                                            app.stalker.reload();
                                        } else {
                                            locked = false;
                                            app.stalker.request(url, options);
                                        }
                                    } else {
                                        locked = false;
                                        app.stalker.request(url, options, true);
                                    }
                                });
                            } else {
                                app.stalker.oncredenitalsrequired(function () {
                                    locked = false;
                                    app.stalker.request(url, options);
                                });
                            }
                        } else {
                            locked = false;

                            try {
                                options.onload(this.status, this.responseText);
                            } catch ( onLoadException ) {
                                if ( app.stalker.onfail ) {
                                    app.stalker.onfail('Exception thrown ' + onLoadException.message);
                                }
                                if ( options.onerror ) {
                                    try {
                                        options.onerror.call(client);
                                    } catch ( error ) {
                                        console.log(error);
                                    }
                                }
                            }

                            if ( requestStack.length > 0 ) {
                                data = requestStack.shift();
                                app.stalker.request(data.url, data.options);
                            }
                        }
                    }
                };

                client.onerror = function () {
                    if ( options.onerror ) {
                        try { options.onerror.call(client); } catch ( err ) { /* */ }
                    }
                    if ( app.stalker.onfail ) {
                        app.stalker.onfail('Network error ' + client.status);
                    }
                };

                client.ontimeout = function () {
                    if ( options.ontimeout ) {
                        try { options.ontimeout.call(client); } catch ( err ) { /* */ }
                    }
                };

                client.send(options.body || null);

                /* eslint consistent-return: 0 */
                return client;
            }
        }
    };

api.auth = {
    login: function ( credentials, callback ) {
        var client    = fetch(),
            postData = ['grant_type=password', 'hw_version=', 'serial_number='],
            ready = 0,
            total    = 5,
            timer    = setTimeout(function () {
                client.abort();
                callback('onerror ' + client.status + ' : ' + client.responseText, false);
            }, 6000);

        postData.push('username=' + encodeURIComponent(credentials.login));
        postData.push('password=' + encodeURIComponent(credentials.password));
        postData.push('activation_code=' + encodeURIComponent(credentials.activationCode));


        credentials.address = credentials.address.replace('/stalker_portal/', '');
        api.host = credentials.address;
        require('./shared.backend').instance.host = api.host;
        api.authHost = credentials.address + '/stalker_portal/auth/token.php';
        client.open('POST', credentials.address + '/stalker_portal/auth/token.php', true);

        client.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        client.timeout = 5000;
        client.onreadystatechange = function () {
            var data;

            clearTimeout(timer);

            if ( this.readyState !== 4 ) {
                return;
            }

            // window.top.alert("login responseText " + this.responseText);
            //alert("login status " + this.status);
            if ( this.status === 200 ) {
                try {
                    data = JSON.parse(this.responseText);
                    //alert(JSON.stringify(data));
                } catch ( parseException ) {
                    console.log(parseException);
                    //alert(e.toString())
                    data = {};
                }

                if ( data.access_token && data.user_id ) {
                    app.stalker.session = {
                        account: credentials,
                        accessToken: null,
                        refreshToken: null,
                        userId: null,
                        macAlgorithm: null,
                        macKey: null
                    };

                    if ( data.activation_code ) {
                        app.stalker.session.account.activationCode = data.activation_code;
                    }

                    app.stalker.session.accessToken = data.access_token;
                    app.stalker.session.refreshToken = data.refresh_token;
                    app.stalker.session.userId = data.user_id;
                    if ( data.mac_algorithm ) {
                        app.stalker.session.macAlgorithm = data.mac_algorithm;
                    }
                    if ( data.mac_key ) {
                        app.stalker.session.macKey = data.api.mac_key;
                        app.stalker.session.macAlgorithm = api.mac_algorithm;
                    }
                    if ( data.api && data.api.v3 ) {
                        app.stalker.session.macKey = data.api.v3.mac_key;
                        app.stalker.session.macAlgorithm = data.api.v3.mac_algorithm;
                    } else {
                        // old ministra server
                        callback(['404']);

                        return;
                    }

                    if ( app.stalker.saveCallback ) {
                        app.stalker.saveCallback();
                    }
                    callback(false, data);
                } else {
                    callback(data, data);
                }
            } else {
                callback(this.status + ' : ' + this.responseText, {});
            }
        };

        client.onerror = function () {
            callback('onerror ' + this.status + ' : ' + this.responseText, false);
        };

        deviceApi.clientType(function ( someProblem, data ) {
            postData.push('client_type=' + encodeURIComponent(data));

            ++ready;
            if ( ready === total ) {
                client.send(postData.join('&'));
            }
        });
        // deviceApi.metric1(function ( someProblem, data ) {
        //     postData.push('meta_1=' + encodeURIComponent(data));
        //
        //     ++ready;
        //     if ( ready === total ) {
        //         client.send(postData.join('&'));
        //     }
        // });
        //credentials.login
        // deviceApi.metric2(function ( someProblem, data ) {
        //     postData.push('meta_2=' + encodeURIComponent(data));
        //
        //     ++ready;
        //     if ( ready === total ) {
        //         client.send(postData.join('&'));
        //     }
        // });

        postData.push('meta_1=' + encodeURIComponent(credentials.login));
        ++ready;
        postData.push('meta_2=' + encodeURIComponent(credentials.login));
        ++ready;

        deviceApi.metric3(function ( someProblem, data ) {
            postData.push('meta_3=' + encodeURIComponent(data));

            ++ready;
            if ( ready === total ) {
                client.send(postData.join('&'));
            }
        });
        deviceApi.network.mac(function ( someProblem, data ) {
            postData.push('mac=' + encodeURIComponent(data));

            ++ready;
            if ( ready === total ) {
                client.send(postData.join('&'));
            }
            // window.top.alert(postData.join('&'))
        });

    },
    refreshToken: function ( refreshToken, callback ) {
        var client    = fetch(),
            ready = 0,
            total = 5,
            postData  = [
                'grant_type=refresh_token',
                'hw_version=',
                'refresh_token=' + app.stalker.session.refreshToken,
                'activation_code=' + encodeURIComponent(app.stalker.session.account.activationCode)
            ];


        postData.push();
        client.open('POST', api.host + '/stalker_portal/auth/token.php', true);
        require('./shared.backend').instance.host = api.host;
        api.authHost = api.host + '/stalker_portal/auth/token.php';

        client.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        client.onload = function () {
            var data;

            //alert("refresh token status " + this.status);
            if ( this.status === 200 ) {
                try {
                    data = JSON.parse(this.responseText);
                } catch ( error ) {
                    console.log(error);
                    data = {};
                }

                //alert("refresh token responseText " + this.responseText);
                if ( data.access_token && data.user_id ) {
                    app.stalker.session.accessToken = data.access_token;
                    app.stalker.session.refreshToken = data.refresh_token;
                    app.stalker.session.userId = data.user_id;
                    if ( data.mac_algorithm ) {
                        app.stalker.session.macAlgorithm = data.mac_algorithm;
                    }
                    if ( data.mac_key ) {
                        app.stalker.session.macKey = app.stalker.mac_key;
                        app.stalker.session.macAlgorithm = app.stalker.mac_algorithm;
                    }
                    if ( data.api && data.api.v3 ) {
                        app.stalker.session.macKey = data.api.v3.mac_key;
                        app.stalker.session.macAlgorithm = data.api.v3.mac_algorithm;
                    }
                    if ( app.stalker.saveCallback ) {
                        app.stalker.saveCallback();
                    }
                    callback(false);
                } else {
                    callback(data, data);
                }
            } else {
                callback(this.status + ' : ' + this.responseText, {});
            }
        };

        client.onerror = function () {
            //alert('onerror');
            callback(true, {});
        };


        deviceApi.clientType(function ( someProblem, data ) {
            postData.push('client_type=' + encodeURIComponent(data));

            ++ready;
            if ( ready === total ) {
                client.send(postData.join('&'));
            }
        });
        deviceApi.metric1(function ( someProblem, data ) {
            postData.push('meta_1=' + encodeURIComponent(data));

            ++ready;
            if ( ready === total ) {
                client.send(postData.join('&'));
            }
        });
        deviceApi.metric2(function ( someProblem, data ) {
            postData.push('meta_2=' + encodeURIComponent(data));

            ++ready;
            if ( ready === total ) {
                client.send(postData.join('&'));
            }
        });
        deviceApi.metric3(function ( someProblem, data ) {
            postData.push('meta_3=' + encodeURIComponent(data));

            ++ready;
            if ( ready === total ) {
                client.send(postData.join('&'));
            }
        });
        deviceApi.network.mac(function ( someProblem, data ) {
            postData.push('mac=' + encodeURIComponent(data));

            ++ready;
            if ( ready === total ) {
                client.send(postData.join('&'));
            }
        });
    }
};

window.fetch = function () {
    return new XMLHttpRequest();
};

module.exports = api;
