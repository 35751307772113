/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const List = require('./list');


module.exports = new List({
    modifiers: ['channels'],
    render: function ( $item, data ) {
        let html = (
            <div className="channel" title={data.name}>
                <div className="channel__logo"></div>
                <div className="channel__name">{data.number}.{data.name}</div>
            </div>
        );

        $item.appendChild(html.$node);
    }
});
