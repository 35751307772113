/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const html = <div className="preloader"><div className="preloader__circle"></div></div>;


module.exports = {
    $node: html.$node,
    show: () => html.$node.classList.add('preloader--visible'),
    hide: () => html.$node.classList.remove('preloader--visible')
};

window.q = module.exports;
