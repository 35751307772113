/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const Component = require('../lib/component');


let content = (
        <div>
            <div className="heading-text">{gettext('Profile')}</div>
            <div className="labled-text">
                <div className="labled-text__label">{gettext('Login')}:</div>
                <div className="labled-text__text" link="$login"></div>
            </div>
            <div className="labled-text">
                <div className="labled-text__label">{gettext('Tariff plan')}:</div>
                <div className="labled-text__text" link="$tariff"></div>
            </div>
            <div className="labled-text">
                <div className="labled-text__label">{gettext('End date')}:</div>
                <div className="labled-text__text" link="$endDate">n/a</div>
            </div>
        </div>
    ),
    page = new Component({
        $node: content.$node,
        name: 'page',
        modifiers: ['profile', 'padded']
    });


page.init = function ( data ) {
    content.links.$login.innerText = data.login;
    content.links.$tariff.innerText = data['tariff_plan'];
    content.links.$endDate.innerText = data['end_date'] || 'n/a';
};


module.exports = page;
