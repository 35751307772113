/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

let provider = require('../../../../../plugin-backend-tv-stalker/src/js/main'),
    plugin;


provider.onAppInit(false, function ( error, worker ) {
    plugin = worker;
});


module.exports = {
    getChannels: ( config = {} ) => {
        return new Promise((resolve, reject) => {
            plugin.updateChannelsList(function () {
                plugin.getChannels(config, function ( error, data ) {
                    if ( error ) {
                        reject(error);
                    } else {
                        resolve(data);
                    }
                });
            });
        });
    },

    getCategories: () => new Promise((resolve, reject) => {
        plugin.getCategories(function ( error, data ) {
            if ( error ) {
                reject();
            } else {
                resolve(data);
            }
        });
    }),

    getLastChannelId: () => new Promise((resolve, reject) => {
        plugin.getLastChannelId((error, data) => {
            if ( error ) {
                reject(error);
            } else {
                resolve(Number(data));
            }
        });
    })
};
