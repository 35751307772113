/**
 *
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

var request = window.core.backend.request;


function Epg ( config ) {
    config = config || {};

    /**
     * Is epg can be downloaded
     */
    this.downloadable = config.downloadable;

    /**
     * Start time ms
     */
    this.start = config.start;

    /**
     * End time ms
     */
    this.end = config.end;

    /**
     * Epg title
     */
    this.title = config.name;

    /**
     * Id
     */
    this.id = config.id;

    /**
     * Is epg in archive
     */
    this.archive = config.in_archive;

    /**
     * Link to channel
     */
    this.channel = config.channel;
}


Epg.prototype = {};
Epg.prototype.constructor = Epg;

/**
 * Return link object contains type, id, and url
 *
 * @param {Function} callback - callback after getting link
 */
Epg.prototype.getLink = function ( callback ) {
    var url = 'tv-channels/' + this.channel.id + '/epg/' + this.id + '/link';

    return request(url, {
        onload: function ( error, response ) {
            if ( error ) {
                callback(true, null);
            } else {
                callback(false, response.data);
            }
        },
        onerror: function () {
            callback(true, null);
        }
    });
};

module.exports = Epg;
