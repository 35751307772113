/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const
    app = require('../../app'),
    ministraApi = require('./stalker.api.unsecure'),
    host = app.config.host,

    USERDATA_NAME = 'desktop-player-session-data',
    ERROR_CODES = require('./statuses').map;


window.core = {
    backend: {
        addListener: function () { /* need empty function for backend modules */ },
        request: ministraApi.request
    },
    stalker: ministraApi
};

app.backend = {};


app.stalker = ministraApi;
app.stalker.lang = app.config.language;

// we must define handler that fires when access or refresh token is broken
app.stalker.oncredenitalsrequired = () => app.emit('authorization:error');

module.exports = {
    STATE_AUTHORIZED: Symbol(),
    STATE_NOT_AUTHORIZED: Symbol(),

    /**
     * Check authorization
     */
    init: function () {
        return new Promise((resolve) => {
            let userData;

            try {
                userData = JSON.parse(localStorage.getItem(USERDATA_NAME));

                Object.assign(ministraApi.session, userData);
                ministraApi.host = app.config.host;
                ministraApi.authHost = app.config.host;
                app.user = {login: userData.userLogin};

                this.ping()
                    .then(() => {
                        resolve({state: this.STATE_AUTHORIZED});
                        app.emit('authorization:login');
                    })
                    .catch(() => resolve({state: this.STATE_NOT_AUTHORIZED}));
            } catch ( error ) {
                console.log(error);
                resolve({state: this.STATE_NOT_AUTHORIZED});
            }
        });
    },

    login: config => {
        let credentials = {
                address: host,
                activationCode: config.activationCode || ''
            },
            userDataToSave = {};

        credentials.login = config.login;
        credentials.password = config.password;

        return new Promise((resolve, reject) => {
            ministraApi.auth.login(credentials, (error, data) => {
                if ( !error ) {
                    app.user = {
                        login: credentials.login
                    };

                    userDataToSave.refreshToken = data['refresh_token'];
                    userDataToSave.accessToken = data['access_token'];
                    userDataToSave.userId = data['user_id'];
                    userDataToSave.userLogin = credentials.login;

                    app.emit('authorization:login');
                    resolve({state: self.STATE_AUTHORIZED});
                    localStorage.setItem(USERDATA_NAME, JSON.stringify(userDataToSave));
                } else {
                    reject({state: ERROR_CODES[error.error_description]});
                }
            });
        });
    },

    signOut: () => {
        localStorage.removeItem(USERDATA_NAME);
        location.reload();
    },

    getSettings: () => {
        return new Promise((resolve, reject) => {
            ministraApi.request('/settings', {
                onload: function ( error, result ) {
                    if ( error ) {
                        reject(error);
                    } else {
                        resolve(result.data);
                    }
                },
                onerror: reject
            });
        });
    },

    getProfileInfo: () => {
        return new Promise((resolve, reject) => {
            ministraApi.request('/profile', {
                onload: function ( error, result ) {
                    if ( error ) {
                        reject(error);
                    } else {
                        resolve(result.data);
                    }
                },
                onerror: reject
            });
        });
    },

    getThemeData: themeAlias => {
        return new Promise((resolve, reject) => {
            ministraApi.request('/themes/' + themeAlias + '?colorSpace=rgba_hex&screenResolution=1920x1080', {
                onload: function ( error, theme ) {
                    if ( error ) {
                        reject();
                    } else {
                        resolve(theme.data);
                    }
                },
                onerror: reject
            });
        });
    },

    getAllUserInfo: async function () {
        let profile = await this.getProfileInfo();

        profile.settings = await this.getSettings();
        profile.theme = await this.getThemeData(profile.settings.themes[profile.settings.theme].alias);

        if ( profile.theme.logoFilename && profile.theme.logoFilename.value ) {
            profile.logoPath = profile.theme.logoFilename.value;
        }

        return profile;
    },

    ping: () => {
        return new Promise((resolve, reject) => {
            ministraApi.request('/ping', {
                onload: function ( error, data ) {
                    if ( error ) {
                        reject(error);
                    } else {
                        resolve(data);
                    }
                },
                onerror: error => {
                    console.log(error, 'ping error');
                    reject(error);
                }
            });
        });
    }
};


if ( DEVELOP ) {
    window.backend = module.exports;
}
