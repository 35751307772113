/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

window.dataLayer = window.dataLayer || [];

function gtag () {
    window.dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', 'UA-152049112-1');
