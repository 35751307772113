/**
 *  Tv - channels plugin or system module implementation
 */

'use strict';

module.exports = {
    onAppInit: function ( config, callback ) {
        var appPlugin = require('./stalker');

        appPlugin(config, callback);
    },
    onInit: require('./daemon.stalker'),
    onContentBoardInit: function ( dump, done ) {
        require('./leanback')(dump, done);
    }
};
