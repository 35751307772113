/**
 * Login page implementation.
 */

'use strict';

const
    keys                = require('spa-keys'),
    format              = require('cjs-format'),
    app                 = require('../app'),
    Component           = require('../lib/component'),
    Input               = require('../lib/components/input'),
    Button              = require('../lib/components/button'),

    backend             = require('../lib/api/backend'),
    spinner             = require('../lib/spinner'),

    authErrors          = require('../lib/api/statuses').errors,

    STATE_INPUT_LOGIN   = Symbol('input_login'),
    STATE_INPUT_LICENSE = Symbol('input_license');


let text = {},
    inputs = {},
    buttons = {},
    credentialsData = {},
    page,
    content,
    state;


function tagB ( innerText, className ) {
    return '<b class="' + className + '">' + innerText + '</b>';
}


function validateInput ( input, minimalLength = 1 ) {
    if ( input.value.trim().length < minimalLength ) {
        input.state = input.STATE_ERROR;
    } else {
        input.state = input.STATE_NORMAL;
    }

    return input.value.trim().length >= minimalLength;
}


function setState ( newState ) {
    state = newState;

    switch ( state ) {
        case STATE_INPUT_LOGIN:
            content.links.$text.innerHTML = text.login;
            inputs.license.hidden = true;
            inputs.login.hidden = false;
            inputs.login.password = false;
            break;
        case STATE_INPUT_LICENSE:
            inputs.login.hidden = true;
            inputs.password.hidden = true;
            inputs.license.hidden = false;
            content.links.$text.innerHTML = text.license;
            break;
    }
}


function processAuthError ( error ) {
    spinner.hide();
    switch ( error.state ) {
        case authErrors.ERROR_WRONG_LOGIN:
            content.links.$loginError.innerText = gettext('Incorrect login or password');
            content.links.$loginError.classList.add('welcome__error--visible');
            break;
        case authErrors.ERROR_ACCOUNT_DISABLED:
            content.links.$loginError.innerText = gettext('Account is disabled');
            content.links.$loginError.classList.add('welcome__error--visible');
            break;
        case authErrors.ERROR_NEED_LICENSE_KEY:
            setState(STATE_INPUT_LICENSE);
            break;
        case authErrors.ERROR_WRONG_LICENSE_KEY:
            content.links.$loginError.innerText = gettext('License key not found');
            content.links.$loginError.classList.add('welcome__error--visible');
            break;
        case authErrors.ERROR_WRONG_META_DATA:
            content.links.$loginError.innerText = gettext('The License Key you’ve entered has already been activated on another device. Please contact the key provider.');
            content.links.$loginError.classList.add('welcome__error--visible');
            break;
        case authErrors.ERROR_WRONG_OPERATOR_ID:
            content.links.$loginError.innerText = gettext('The License Key doesn\'t work for this portal. Please contact your service provider.');
            content.links.$loginError.classList.add('welcome__error--visible');
            break;
        default:
            content.links.$loginError.innerText = gettext('Undefined error');
            content.links.$loginError.classList.add('welcome__error--visible');
            break;
    }
}


function loginHandler () {
    let login     = inputs.login.value,
        password  = inputs.password.value;

    if ( validateInput(inputs.login) && validateInput(inputs.password) ) {
        credentialsData.login = login;
        credentialsData.password = password;
        content.links.$loginError.classList.remove('welcome__error--visible');
        if ( state === STATE_INPUT_LICENSE ) {
            if ( !validateInput(inputs.license, app.config.licenseKeyLength) ) {
                content.links.$loginError.innerText = gettext('License Key must have 12 symbols');
                content.links.$loginError.classList.add('welcome__error--visible');

                return;
            }
            credentialsData.activationCode = inputs.license.value;
        }

        spinner.show();
        backend.login(credentialsData)
            .then(() => {
                spinner.hide();
                app.tabSet.current = app.pages.channels;
            })
            .catch(processAuthError);
    }
}


function inputKeydownHandler ( event ) {
    event.keyCode === keys.enter && loginHandler();
}


text.login = format(
    gettext('Contact your service provider and request {0} and {1} from them .'),
    tagB(gettext('login')),
    tagB(gettext('password'))
);
text.license = format(
    gettext('Contact your service provider and request {0} from them.'),
    tagB(gettext('license key'))
);

inputs.login = new Input({
    label: gettext('Login'),
    // defined only in develop mode
    value: app.config.user.login,
    events: {keydown: inputKeydownHandler}
});

inputs.password = new Input({
    label: gettext('Password'),
    type: 'password',
    // defined only in develop mode
    value: app.config.user.password,
    events: {keydown: inputKeydownHandler}
});

inputs.license = new Input({
    label: gettext('License key'),
    // defined only in develop mode
    value: app.config.user.activationKey,
    events: {keydown: inputKeydownHandler}
});

buttons.login = new Button({
    title: gettext('Sign in'),
    events: {
        click: loginHandler
    }
});


/* eslint max-len: 0*/
content = (
    <div>
        <div className="welcome">
            <img className="welcome__logo" src="./img/ministra.logo.svg"></img>
            <div className="welcome__title">{gettext('Welcome to Ministra Player')}</div>
            <div link="$text" className="welcome__text text text--hint"></div>
            <div className="welcome__error" link="$loginError"></div>
            <div className="welcome__error" link="$licenseError">{gettext('The License Key has been suspended. Please contact your service provider.')}</div>
            {inputs.login}
            {inputs.password}
            {inputs.license}
            {buttons.login}
        </div>
    </div>
);


page = new Component({
    name: 'page',
    modifiers: ['login'],
    $node: content.$node
});

setState(STATE_INPUT_LOGIN);

// public
module.exports = page;
