/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const
    Emitter = require('cjs-emitter'),
    config  = require('../config');

let html = (
        <div className="player">
            <div link="$title" className="player__title"></div>
            <video link="$video" className="video-js" width="544" height="322"></video>
            <div link="$liveButton" className="player__live-button">{gettext('Live')}</div>
            <div link="$description" className="player__description"></div>
        </div>
    ),
    player = new Emitter(),
    lastChannelTimeout,
    lastPlayedContent;

player.$node = html.$node;
player.links = html.links;


function onPlayHandler ( /*event*/ ) {
    if ( player.events.play && player.events.play.length ) {
        player.emit('play');
    }
}


function onPlayEnd () {
    if ( player.events.end && player.events.end.length ) {
        player.emit('end');
    }
}

player.init = function () {
    /*eslint-disable-next-line*/
    this.plugin = videojs(html.links.$video, {
        controls: true,
        preload: true
    });

    this.plugin.on('play', onPlayHandler);
    this.plugin.on('ended', onPlayEnd);
    this.links.$liveButton.addEventListener('click', () => this.emit('click:live'));

    return html.$node;
};


player.playChannel = function ( channel ) {
    if ( lastPlayedContent === channel ) {
        return;
    }

    lastPlayedContent = channel;
    this.links.$title.innerText = `${channel.number}. ${channel.name}`;
    this.links.$liveButton.classList.remove('player__live-button--visible');

    channel.getUrl((error, data) => {
        if ( error ) {
            console.log(error);
        }

        this.plugin.src({
            src: data.url
        });

        this.plugin.play().then(function () {
            clearTimeout(lastChannelTimeout);
            lastChannelTimeout = setTimeout(function () {
                channel.setLast();
            }, config.saveLastChannelTimeout);
        });
    });
};


player.playEpgProgram = function ( playConfig = {} ) {
    lastPlayedContent = playConfig;
    this.links.$liveButton.classList.add('player__live-button--visible');

    if ( playConfig.uri ) {
        this.plugin.src({
            src: playConfig.uri
        });

        this.plugin.play();
    }
};


player.pause = function () {
    this.plugin.pause();
};

module.exports = player;
