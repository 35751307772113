/**
 *
 * @author Aleynikov Boris <alynikov.boris@gmail.com>.
 */

'use strict';

var request = core.backend.request,
    Epg = require('./epg.class'),
    storageRequest;

function Channel ( config ) {
    var prop;

    config = config || {};

    if ( PLATFORM !== 'MAG') {
        // eslint-disable-next-line
        config.pvr = config.local_pvr = false;
    }

    for ( prop in config ) {
        this[prop] = config[prop];
    }
}


Channel.prototype = {};
Channel.prototype.constructor = Channel;

/**
 * Toggle favorite state
 *
 * @param {Function} callback - callback after toggle
 */
Channel.prototype.toggleFavorite = function ( callback ) {
    var self = this;

    request('tv-channels/' + this.id, {
        method: 'PUT',
        body: JSON.stringify({favorite: !self.favorite}),
        onload: function ( error ) {
            if ( error ) {
                callback(true);
            } else {
                self.favorite = !self.favorite;
                callback(false, self.favorite);
            }
        },
        onerror: function () {
            callback(true);
        }
    });
};


/**
 * Load channel url.
 *
 * @param {Function} callback - which called with or without url
 *
 * @return {XMLHttpRequest|null} request instance or null for cached url
 */
Channel.prototype.getUrl = function ( callback ) {
    if ( this.url ) {
        callback(false, {url: this.url, solution: this.solution});
    } else {
        return request('tv-channels/' + this.id + '/link', {
            onload: function ( error, response ) {
                if ( error ) {
                    callback(error, null);
                } else {
                    callback(false, {url: response.data.url, solution: response.data.solution});
                }
            },
            onerror: function () {
                callback(true, null);
            }
        });
    }

    return null;
};


Channel.prototype.getEpg = function ( config, callback ) {
    var self = this,
        now = new Date(),
        url, from, to;

    config = config || {};

    // use 7 days before current and next 7 days to get epg
    from = +new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    to =  +new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
    from = Math.round(from / 1000);
    to  = Math.round(to / 1000);

    from = config.from || from;
    to = config.to || to;

    url = 'tv-channels/' + this.id + '/epg/?from=' + from + '&to=' + to;

    function onRequestError () {
        callback(true, []);
    }

    return request(url, {
        onload: function ( error, response ) {
            var result = [];

            if ( error && callback ) {
                console.log(error);
                // TODO: split this check in two
                callback(true, []);
            } else {
                response.data.forEach(function ( epgData ) {
                    epgData.channel = self;
                    result.push(new Epg(epgData));
                });

                callback(false, result);
            }
        },
        onerror: onRequestError,
        ontimeout: onRequestError
    });
};

Channel.prototype.setLast = function ( callback ) {
    if ( storageRequest ) {
        storageRequest.abort();
    }

    function onRequestError () {
        storageRequest = null;

        callback(true, null);
    }

    storageRequest = request('storage', {
        body: JSON.stringify({value: this.id, key: 'lastChannel'}),
        method: 'POST',
        onload: function ( error ) {
            storageRequest = null;

            if ( error && callback ) {
                callback(error);
            } else if ( callback ) {
                callback(false);
            }
        },
        onerror: onRequestError,
        ontimeout: onRequestError
    });
};


module.exports = Channel;
