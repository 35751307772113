/**
 * Global application configuration.
 * Can store run-time options, API urls, paths, execution flags and so on.
 */

'use strict';

// public
module.exports = {
    language: localStorage.getItem('language') || navigator.language.split('-')[0].toLowerCase() || 'en',

    // list of supported languages
    supportedLanguages: [
        {code: 'ru', title: 'rus'},
        {code: 'en', title: 'eng'}
    ],

    host: DEVELOP ?
        'http://10.110.51.11/'
        : location.protocol + '//' + location.host + '/',
    user: {},

    // program list update interval in ms
    programListUpdateInterval: 5 * 60 * 1000,

    // time after channel playing to set as last played
    saveLastChannelTimeout: 3000,

    // license key length to validate
    licenseKeyLength: 12
};
