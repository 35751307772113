/**
 * Application core.
 */

'use strict';

const
    Emitter = require('cjs-emitter'),
    app     = new Emitter();


// load global app configuration
app.config = require('./config');

if ( DEVELOP ) {
    window.app = app;
}


// public
module.exports = app;
