/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

function onProgramFocus ( program ) {
    if ( program.archive ) {
        program.getLink((error, data) => {
            if ( !error && data && data.url ) {
                player.playEpgProgram({uri: data.url});
            }
        });
    } else {
        player.playChannel(channelList.data[channelList.focusIndex]);
    }
}

function onChannelFocus ( channel ) {
    let today = new Date(),
        todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime() / 1000,
        nextDayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).getTime() / 1000;

    player.playChannel(channel);

    channel.getEpg({from: todayStart, to: nextDayStart}, (error, programs) => {
        if ( error ) {
            console.error(error);
            programList.data = [];
        } else {
            programList.data = programs;
        }
    });
}

/**
 * Player's 'end' event handler
 * On this page we may catch 'end' event only when playing tv archive.
 */
function onContentEnd () {
    console.log('end');
    if ( programList.data[programList.focusIndex + 1] ) {
        programList.focusIndex++;
    } else {
        // play realtime channel
        player.playChannel(channelList.data[channelList.focusIndex]);
    }
}


const
    Component = require('../lib/component'),
    Select = require('../lib/components/select'),
    provider = require('../lib/api/tv.provider'),
    header = require('../lib/components/header'),
    spinner = require('../lib/spinner'),
    player = require('../lib/player'),
    channelList = require('../lib/components/channel.list'),
    programList = require('../lib/components/program.list'),

    categorySelect = new Select({
        events: {
            'focus:item': function ( element ) {
                spinner.show();
                provider.getChannels({genre: element.value.id})
                    .then(channels => {
                        channelList.data = channels;
                        spinner.hide();
                    });
            }
        }
    }),
    content = (
        <div>
            <div className="page__channel-list channel-list">
                {categorySelect}
                {channelList}
            </div>
            {player}
            <div className="page__program-list program-list">
                <div className="program-list__title">
                    {gettext('Guide')}
                </div>
                {programList}
            </div>
        </div>
    ),
    page = new Component({
        $node: content.$node,
        name: 'page',
        modifiers: ['channels', 'padded']
    });


player.init();
player.addListener('click:live', () => {
    player.playChannel(channelList.data[channelList.focusIndex]);
    programList.focusIndex = null;
});


page.addListeners({
    show: () => {
        header.hidden = false;
        spinner.show();
        player.addListener('end', onContentEnd);

        provider.getChannels().then(channels => {
            channelList.data = channels;
            spinner.hide();

            provider.getLastChannelId().then(lastId => {
                let index = channelList.data.findIndex(channel => channel.id === lastId);

                if ( index === -1 ) {
                    index = 0;
                }

                channelList.focusIndex = index;
            }).catch(() => {
                channelList.focusIndex = 0;
            });

            // once page was shown we must play content and pause to draw preview image
            // make setTimeout because on play event first frame is not drawn
            player.once('play', () => {
                setTimeout(() => {
                    player.plugin.pause();
                });
            });

        });

        provider.getCategories().then(categories => {
            categories = categories.map(category => {
                return {
                    label: category.name,
                    value: category
                };
            });

            categorySelect.data = [
                {
                    label: gettext('All channels'),
                    value: {id: null}
                }
            ].concat(categories);

            categorySelect.current = categorySelect.data[0];
        });
    },

    hide: () => {
        player.removeListener('end', onContentEnd);
        player.pause();
    }
});


// prepare channels page
{
    categorySelect.$node.classList.add('channel-list__select');
    channelList.addListener('focus:item', onChannelFocus);
    programList.addListener('focus:item', onProgramFocus);
}

if ( DEVELOP ) {
    window.player = player;
}


module.exports = page;
