/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const
    Component       = require('../component'),
    List            = require('./list'),
    Select          = require('./select'),
    app             = require('../../app'),
    backend         = require('../api/backend'),

    menu = new List({
        modifiers: ['header'],
        hidden: true,
        data: [
            {text: gettext('Profile'), action: () => (app.tabSet.current = app.pages.profile)},
            {text: gettext('Sign out'), action: backend.signOut}
        ],
        render: ($item, data) => ($item.innerText = data.text)
    }),
    languageSelect = new Select({
        data: app.config.supportedLanguages.map(language => ({label: language.title.toUpperCase(), value: language})),
        events: {
            'focus:item': data => {
                if ( data.value.code !== app.config.language ) {
                    localStorage.setItem('language', data.value.code);
                    location.reload();
                }
            }
        }
    }),
    html = (
        <div>
            <div className="header__content">
                <img link="$logo" className="header__logo" src='./img/logo.placeholder.svg'/>
                <div link="$tvChannel" className="header__menu-item">{gettext('TV channels')}</div>
                <div className="header__user">
                    <div className="header__user-logo"></div>
                    <div link="$userName" className="header__user-name">John doe</div>
                    {menu}
                </div>
                {languageSelect}
            </div>
        </div>
    ),
    header = new Component({
        name: 'header',
        hidden: true,
        $node: html.$node
    });


// language select start initialization
{
    languageSelect.$node.classList.add('header__select');
    languageSelect.current = languageSelect.data[languageSelect.data.findIndex(data => data.value.code === app.config.language)];
}

// set header module getters and setters
Object.defineProperties(header, {
    userName: {
        set: name => {
            header.private.userName = name;
            html.links.$userName.innerText = name;
        },
        get: () => header.private.userName
    },

    logo: {
        set: path => {
            header.private.logoPath = path;
            html.links.$logo.src = path;
        },
        get: () => header.private.logoPath
    }
});


// add components listeners
{
    menu.addListener('focus:item', element => {
        element.action && element.action();

        // focus:item event will not fire if we click on focused item, so we need to reset this value
        menu.$focusItem = null;
    });

    html.links.$userName.addEventListener('click', () => {
        menu.hidden = !menu.hidden;
    });

    html.links.$tvChannel.addEventListener('click', () => {
        if ( app.tabSet.current !== app.pages.channels ) {
            app.tabSet.current = app.pages.channels;
        }
    });

    document.addEventListener('click', event => {
        if ( event.target !== html.links.$userName ) {
            menu.hidden = true;
        }

        if ( event.target !== languageSelect.links.$value ) {
            languageSelect.links.list.hidden = true;
        }
    });
}


module.exports = header;
