/**
 *
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

var Emitter               = require('cjs-emitter'),
    storage               = require('./data.storage'),

    // constants
    EVENT_BACKEND_LOAD    = 'system:start',
    EVENT_MESSAGE_RECEIVE = 'play_channel',
    TV_APP_NAME           = 'magcore-app-tv',
    CLASSIC_TV_APP_NAME   = 'magcore-app-tv-classic',

    appsConfig            = {
        /* here will be apps configs */
    };

function isAppRunning ( appName ) {

    return (core.taskManager.topApp && core.taskManager.topApp.config.packageName === appName);
}


function sendPlayChannelEvent ( channelToPlay ) {
    var targetApp,
        channelToCheckCensored;

    // if we are watching TV Classic app we do not need do checks
    if ( isAppRunning(CLASSIC_TV_APP_NAME) ) {
        targetApp = appsConfig.CLASSIC_TV;
    } else if ( isAppRunning(TV_APP_NAME) ) {
        // if we are watching TV app we do not need do checks
        targetApp = appsConfig.TV;
    } else if ( appsConfig.CLASSIC_TV && !appsConfig.CLASSIC_TV.locked ) {
        // if classic tv is present and not under parent password
        targetApp = appsConfig.CLASSIC_TV;
    } else if ( appsConfig.TV && (!appsConfig.TV.locked || isAppRunning(TV_APP_NAME))) {
        // if tv app is present and not under parent password
        targetApp = appsConfig.TV;
    } else {
        console.log('we cant send message to tv apps :(');
    }

    channelToCheckCensored = storage.findByNumber(channelToPlay);

    if (
        channelToCheckCensored
        && (channelToCheckCensored.censored || ( channelToCheckCensored.genre && channelToCheckCensored.genre.censored ))
    ) {
        // this channel is under parent control and we should do nothing
        return;
    }

    core.taskManager.runApp({
        config: targetApp,
        ignoreAccessControl: true,
        visible: true,
        onload: function ( app ) {
            setTimeout(function () {
                app.core.emit('play:channel', {number: parseInt(channelToPlay, 10)});
            }, 100);
        }
    });
}


// window.top.qm = {
//     START_POINT_TIMESTAMP: +new Date(),
//     send: function ( n ) {
//         sendPlayChannelEvent(n);
//     },
//     storage: storage
// };

module.exports = function ( config, callback ) {
    var hardcodeLoader = new Emitter();


    hardcodeLoader.once(EVENT_BACKEND_LOAD, function () {
        // find apps links
        appsConfig.TV = core.packageMap[TV_APP_NAME];
        appsConfig.CLASSIC_TV = core.packageMap[CLASSIC_TV_APP_NAME];

        // get app provider to force update channel list
        require('./stalker')(config, function ( error, appProvider ) {
            if ( error ) {
                console.log('error getting provider');
            }
            appProvider.updateChannelsList(function () {
                window.core.backend.addListener('message', function ( message ) {
                    if ( message && message.type === EVENT_MESSAGE_RECEIVE ) {
                        // here we have channel id or number to play
                        sendPlayChannelEvent(message.msg);
                    }
                });

            });
        });
    });

    if ( callback ) {
        callback(false, hardcodeLoader);
    }

};
