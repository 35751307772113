/**
 * This file was generated automatically. Do not edit it anyway.
 */

'use strict';

module.exports = {
    errors: {
        ERROR_NEED_LICENSE_KEY: 0,
        ERROR_ACCOUNT_DISABLED: 1,
        ERROR_WRONG_LICENSE_KEY: 2,
        ERROR_WRONG_LOGIN: 3,
        ERROR_WRONG_META_DATA: 4,
        ERROR_WRONG_OPERATOR_ID: 5
    },
    map: {
        'Automatic activation code issuing disabled for user.': 0,
        'Account is disabled': 1,
        'License key not found': 2,
        'Username or password is incorrect': 3,
        'Meta data mismatch': 4,
        'Operator ID mismatch': 5
    }
};
