/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

var Component = require('../component');


class Input extends Component {
    constructor ( config ) {
        let html;

        config = config || {};
        config.type = config.type || 'text';

        html = (
            <div>
                <div link="$label" className = {config.label ? 'input__label' : 'input__label--hidden'} >{config.label}</div>
                <input link="$value" className="input__value" type={config.type}>{config.value || '' }</input>
            </div>
        );

        config.$node = html.$node;

        super(config);

        this.links = html.links;
        this.private.state = this.STATE_NORMAL;

        this.links.$value.addEventListener('change', () => {
            if ( this.events.change ) {
                this.emit('change', {value: this.$node.value});
            }
        });

        this.links.$value.addEventListener('keydown', event => {
            if ( this.events.keydown ) {
                this.emit('keydown', event);
            }
        });

        if ( config.placeholder ) {
            this.links.$value.placeholder = config.placeholder;
        }

        if ( config.value ) {
            this.links.$value.value = config.value;
        }
    }

    get value () {
        return this.links.$value.value;
    }

    set state ( state ) {
        switch ( state ) {
            case this.STATE_ERROR:
                this.links.$value.className = 'input__value input__value--error';
                break;
            case this.STATE_NORMAL:
                this.links.$value.className = 'input__value';
                break;
        }

        this.private.state = state;
    }

    get state () {
        return this.private.state;
    }
}

Input.prototype.STATE_NORMAL = 0;
Input.prototype.STATE_ERROR = 1;
Input.prototype.name = 'input';

module.exports = Input;
