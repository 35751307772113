/**
 *
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

var Emitter                 = require('cjs-emitter'),
    Channel                 = require('./channel.class'),
    Epg                     = require('./epg.class'),

    storage                 = require('./data.storage'),

    instanceList            = [],
    request                 = window.core.backend.request,

    // count of channels to get per request to init provider
    INIT_CHANNELS_LIMIT     = 500,
    // every 3 minutes update channels
    UPDATE_CHANNELS_TIMEOUT = 60000 * 3,
    // delay between intervals
    UPDATE_INTERVALS_DELAY  = 15000,

    BACKGROUND_INTERVAL_TO  = 60,
    BACKGROUND_INTERVAL_FROM = 30,

    EMPTY_INTERVAL_COUNT    = 10,
    // LEANBACK_ITEM_COLOR     = '#009bc2',

    emptyCounter            = {
        addCount: 0,
        updateCount: 0,
        deleteCount: 0
    },
    tempChannelsData        = [],
    intervals               = {},

    LAST_CHANNELS_ADD,
    LAST_CHANNELS_UPDATE,
    LAST_CHANNELS_DELETE,


    // when collecting channels in parts save channels in tmp array
    totalChannels;

function addChannelsHandler () {
    var url;

    if ( !instanceList.length && emptyCounter.addCount < EMPTY_INTERVAL_COUNT ) {
        emptyCounter.addCount++;

        return;
    }
    url = 'tv-channels/added?from=' + LAST_CHANNELS_ADD + '&t=' + +new Date();
    emptyCounter.addCount = 0;
    request(url, {
        onload: function ( error, response ) {
            if ( !error && response ) {
                LAST_CHANNELS_ADD = response.timestamp;
                if ( response.data.length ) {

                    response.data = response.data.map(function ( object ) {
                        return new Channel(object);
                    });
                    storage.list = storage.list.concat(response.data);
                    instanceList.forEach(function ( provider ) {
                        provider.emit('channels:add', {data: response.data});
                    });
                }
            }
        }
    });
}

function deleteChannelsHandler () {
    if ( !instanceList.length && emptyCounter.deleteCount < EMPTY_INTERVAL_COUNT ) {
        emptyCounter.deleteCount++;

        return;
    }
    emptyCounter.deleteCount = 0;
    request('tv-channels/deleted?from=' + LAST_CHANNELS_DELETE + '&t=' + +new Date(), {
        onload: function ( error, response ) {
            if ( !error && response ) {
                LAST_CHANNELS_DELETE = response.timestamp;
                if ( response.data.length ) {
                    storage.list = storage.list.filter(function (ch) {
                        return (response.data.indexOf(ch.id) < 0);
                    });
                    instanceList.forEach(function ( provider ) {
                        provider.emit('channels:delete', {data: response.data});
                    });
                }
            }
        }
    });
}

function updateChannelsHandler () {
    if ( !instanceList.length && emptyCounter.updateCount < EMPTY_INTERVAL_COUNT ) {
        emptyCounter.updateCount++;

        return;
    }
    emptyCounter.updateCount = 0;
    request('tv-channels/modified?from=' + LAST_CHANNELS_UPDATE + '&t=' + +new Date(), {
        onload: function ( error, response ) {
            var hash = {},
                index;

            if ( !error && response ) {
                LAST_CHANNELS_UPDATE = response.timestamp;
                if ( response.data.length ) {

                    response.data.forEach(function ( updated ) {
                        hash[updated.id] = new Channel(updated);
                    });

                    for ( index = 0; index < storage.list.length; index++ ) {
                        if ( hash[storage.list[index].id] ) {
                            storage.list[index] = hash[storage.list[index].id];
                        }
                    }

                    instanceList.forEach(function ( provider ) {
                        provider.emit('channels:modified', {data: response.data});
                    });

                }
            }
        }
    });
}

/**
 * Start to recursive grab channels
 *
 * @param {Object} [config] - config to grab
 * @param {Function} callback - callback after getting channels
 */
function getChannels ( config, callback ) {
    var url = 'tv-channels',
        // add some timestamp to prevent cache request on mag 256
        timestamp = Date.now() / 1000;

    config = config || {};


    url += '?limit=' + INIT_CHANNELS_LIMIT;

    if ( config.offset ) {
        url += '&offset=' + config.offset;
    }
    url += '&t=' + timestamp;
    request(url, {
        onload: function ( error, response ) {
            var index;

            if ( error ) {
                // do sth

            } else {
                for ( index = 0; index < response.data.length; index++ ) {
                    tempChannelsData.push(new Channel(response.data[index]));
                }

                if ( !totalChannels ) {
                    totalChannels = response.paging.total;
                }
                // save config and set offset
                if ( tempChannelsData.length < totalChannels ) {
                    config.offset = tempChannelsData.length;
                    getChannels(config, callback);
                } else {
                    // clear tmp data
                    storage.list = tempChannelsData;
                    tempChannelsData = [];
                    totalChannels = 0;

                    config.eventName = config.eventName || 'ready';

                    if (config.update) {
                        instanceList.forEach(function ( provider ) {
                            provider.emit('update');
                        });
                    }
                    if ( callback ) {
                        callback(false);
                    }
                    LAST_CHANNELS_ADD = LAST_CHANNELS_DELETE = LAST_CHANNELS_UPDATE = response.timestamp;
                }

            }
        },
        onerror: function () {
            callback(true);
        },
        onabort: function () {
            callback(true);
        }
    });
}

window.core.backend.addListener('message', function ( message ) {
    switch ( message.type ) {
        case 'update_subscription':
            getChannels({update: true});
            break;
    }
});


intervals.addChannelsInterval = setInterval(addChannelsHandler, UPDATE_CHANNELS_TIMEOUT);

setTimeout(function () {
    intervals.deleteChannelsInterval = setInterval(deleteChannelsHandler, UPDATE_CHANNELS_TIMEOUT);
}, UPDATE_INTERVALS_DELAY);

setTimeout(function () {
    intervals.updateChannelsInterval = setInterval(updateChannelsHandler, UPDATE_CHANNELS_TIMEOUT);
}, UPDATE_INTERVALS_DELAY * 2);

/**
 * Channel list provider implementation
 *
 * @constructor
 */
function Provider () {
    Emitter.call(this);

    /**
     * Id of backround check timeout
     */
    this.backgroungCheckInterval = null;
}


Provider.prototype = Object.create(Emitter.prototype);
Provider.prototype.constructor = Provider;

/**
 * Get channel list
 *
 * @param {Object} config - config to get channels
 * @param {Function} callback - callback after getting data
 */
Provider.prototype.getChannels = function ( config, callback ) {
    var result = storage.list,
        reg;

    if ( config.favorite ) {
        result = result.filter(function ( channel ) {
            return !!channel.favorite;
        });
    }
    if ( config.genre ) {
        result = result.filter( function ( channel ) {
            return channel.genre.id === config.genre;
        });
    }
    if ( config.byName ) {
        result = result.sort(function ( itemA, itemB ) {
            return itemA.name > itemB.name ? 1 : -1;
        });
    } else {
        result = result.sort(function ( itemA, itemB ) {
            return itemA.number > itemB.number ? 1 : -1;
        });
    }

    if ( config.pvr ) {
        result = result.filter( function ( channel ) {
            return ( channel.pvr || channel.local_pvr );
        });
    }

    if ( config.search ) {
        reg = new RegExp(config.search, 'i');
        result = result.filter(function ( channel ) {
            var matchName = channel.name.match(reg),
                matchNumber = channel.number.toString().match(reg);

            return ((matchName && matchName.length) || (matchNumber && matchNumber.length));
        });
    }
    callback(false, result);
};

/**
 * Request full update channels list
 *
 * @param {Function} callback - callback after all done
 */
Provider.prototype.updateChannelsList = function ( callback ) {
    getChannels(null, callback);
};

Provider.prototype.getCategories = function ( callback ) {
    request('tv-genres', {
        onload: function ( error, response ) {
            if ( error ) {
                if ( callback ) {
                    callback(true);
                }

                return;
            }
            if ( callback ) {
                callback(false, response.data);
            }
        },
        onerror: function () {
            if ( callback ) {
                callback(true);
            }
        }
    });
};

Provider.prototype.getLastChannelId = function ( callback ) {
    request('storage/lastChannel', {
        method: 'GET',
        onload: function ( error, response ) {
            if ( error && callback ) {
                callback(error, null);
            } else if ( callback && response.data.value ) {
                callback(false, response.data.value);
            }
        },
        onerror: function () {
            if ( callback ) {
                callback(true, null);
            }
        },
        onabort: function () {
            if ( callback ) {
                callback(true, null);
            }
        }
    });
};

Provider.prototype.getEpgLink = function ( config, callback ) {
    var url = 'tv-channels/' + config.channelId + '/epg/' + config.epgId + '/link';

    request(url, {
        onload: function ( error, response ) {
            if ( error ) {
                callback(true, []);
            } else {
                callback(false, response.data);
            }
        },
        onerror: function () {
            callback(true, []);
        }
    });
};


Provider.prototype.getChannelsEpg = function ( config, callback ) {
    var map = {},
        multiple,
        url,
        channelArray,
        from,
        to,
        params;

    config = config || {};

    channelArray = config.channels;
    multiple = channelArray.length > 1;


    if ( !config.from && !config.to ) {
        params = 'next=1';
    } else {
        from = Math.round(config.from / 1000) || '';
        to  = Math.round(config.to / 1000) || '';
        params = 'from=' + from + '&to=' + to;
    }
    channelArray.forEach(function ( channel ) {
        map[channel.id] = channel;
    });

    url = 'tv-channels/' + encodeURIComponent(channelArray.map(function ( channel ) {
        return channel.id;
    }).join(',')) + '/epg?' + params;

    return request(url, {
        onload: function ( error, response ) {
            var result = {};

            if ( error && callback ) {
                callback(true, []);
            } else {
                Object.keys(response.data).forEach(function ( key ) {
                    var channelId = multiple ? key : channelArray[0].id,
                        targetData = multiple ? response.data[key] : response.data;

                    result[channelId] = [];
                    targetData.forEach(function ( epgData ) {
                        epgData.channel = map[channelId];
                        result[channelId].push(new Epg(epgData));
                    });
                });
                callback(false, result);
            }
        },
        onerror: function () {
            callback(true, []);
        }
    });
};

// destroy remove this instance from instance list
Provider.prototype.destroy = function () {
    var index = instanceList.indexOf(this);

    if ( index ) {
        instanceList = instanceList.splice(index, 1);
    }
};

Provider.prototype.startBackgroundCheck = function () {
    var self = this;

    function getRandomTimeout () {
        var seconds = (Math.floor(Math.random() * BACKGROUND_INTERVAL_TO) + BACKGROUND_INTERVAL_FROM ) * 60 * 1000;

        // console.log('channels:updater: get interval ' + seconds);

        if ( seconds / 60000 > 60 ) {
            seconds = getRandomTimeout();
        }

        return seconds;
    }

    function update () {
        var previousCount = storage.list.length;

        // console.log('channels:updater: was length: ' + previousCount);

        getChannels({}, function ( error ) {
            if ( error ) {
                // console.log('channels:updater: error updating channels');
            }

            // console.log('channels:updater: new length: ' + storage.list.length);
            if ( previousCount !== storage.list.length ) {
                self.emit('update');
            }

            self.backgroungCheckInterval = setTimeout(update, getRandomTimeout());
        });
    }

    this.backgroungCheckInterval = setTimeout(update, getRandomTimeout());
};


Provider.prototype.stopBackgroundCheck = function () {
    clearTimeout(this.backgroungCheckInterval);
};

function getAppProvider ( config, callback ) {
    var provider = new Provider();


    instanceList.push(provider);

    callback(null, provider);
}

module.exports = getAppProvider;
