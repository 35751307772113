/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const
    Component = require('../component'),
    List      = require('../components/list');


class Select extends Component {
    constructor ( config = {} ) {
        let list = new List({
                modifiers: ['select'],
                hidden: true,
                render: ( $item, data ) => ($item.innerText = data.label)
            }),
            html = (
                <div>
                    <div link="$value" className="select__value"></div>
                    {list}
                </div>
            ),
            self;


        config.$node = html.$node;
        config.name = config.name || 'select';

        super(config);

        self = this;

        this.links = html.links;
        this.links.list = list;

        this.private.data = [];
        this.private.current = config.current;

        this.current = config.current;

        if ( config.data ) {
            this.data = config.data;
        }

        this.$node.addEventListener('click', function () {
            self.links.list.hidden = !self.links.list.hidden;
        });

        list.addListener('focus:item', function ( element ) {
            self.links.$value.innerText = element.label || '';
            self.emit('focus:item', element);
        });
    }

    set data ( array ) {
        if ( !Array.isArray(array) ) {
            throw new Error(__filename + ': wrong data type');
        }

        this.private.data = array;
        this.links.list.data = array;
    }

    get data () {
        return this.private.data;
    }

    get current () {
        return this.private.current;
    }

    set current ( value ) {
        if ( value ) {
            this.private.current = value;
            this.links.$value.innerText = value.label;
        }
    }
}


module.exports = Select;
