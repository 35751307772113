/**
 * Main application entry point.
 */

'use strict';

const
    gettext = require('spa-gettext'),
    TabSet  = require('./lib/components/tab-set'),
    app     = require('./app'),
    backend = require('./lib/api/backend'),
    spinner = require('./lib/spinner');


require('./lib/analytics');

// load localization
gettext.load({name: app.config.language}, function () {
    let pages  = {},
        tabSet = new TabSet({
            $node: document.body,
            content: [
                pages.login = require('./pages/login'),
                pages.channels = require('./pages/channels'),
                pages.profile = require('./pages/profile')
            ]
        });

    app.tabSet = tabSet;
    app.pages = pages;
    app.header = require('./lib/components/header');

    app.addListener('authorization:login', function () {
        app.header.userName = app.user.login;

        backend.getAllUserInfo().then(userInfo => {
            pages.profile.init(userInfo);

            if ( userInfo.logoPath ) {
                app.header.logo = userInfo.logoPath;
            }
        });
    });

    // when auth error happend we must show login page
    app.addListener('authorization:error', () => backend.signOut());

    document.body.appendChild(require('./lib/spinner').$node);
    document.body.appendChild(app.header.$node);

    spinner.show();
    backend.init().then(function ( result ) {
        spinner.hide();

        if ( result.state === backend.STATE_NOT_AUTHORIZED ) {
            tabSet.current = pages.login;
        } else if ( result.state === backend.STATE_AUTHORIZED ) {
            tabSet.current = pages.channels;
        }
    });
});
