/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const
    List      = require('./list'),
    isEnglish = require('../../app').config.language === 'en';


function convertTime ( time ) {
    let startTime = new Date(time * 1000),
        hours = startTime.getHours(),
        minutes = startTime.getMinutes(),
        ampm = hours >= 12 ? 'pm' : 'am',
        result;

    if ( isEnglish ) {
        hours = hours % 12;
        hours = hours ? hours : 12;
    }

    minutes = minutes < 10 ? '0' + minutes : minutes;
    result = hours + ':' + minutes;

    if ( isEnglish ) {
        result += ' ' + ampm;
    }

    return result;
}


function findCurrentProgram ( program ) {
    let nowTime = Date.now() / 1000;

    return program.start < nowTime && program.end > nowTime;
}


let programList = new List({
    modifiers: ['program-list'],
    render: function ( $item, data ) {
        let
            className = 'program'
                 + (data.archive ? ' program--archive' : '')
                 + (findCurrentProgram(data) ? ' program--current' : ''),
            html = (
                <div className={className} title={data.title}>
                    <div className="program__time">{convertTime(data.start)}</div>
                    <div className="program__name">{data.title}</div>
                </div>
            );

        while ( $item.firstChild ) {
            $item.removeChild($item.firstChild);
        }

        $item.appendChild(html.$node);
    }
});


setInterval(function () {
    programList.data.forEach(function ( data, index ) {
        programList.render(programList.$node.children[index], data);
    });
}, require('../../app').config.programListUpdateInterval);


module.exports = programList;
