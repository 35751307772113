/**
 *
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

var storage;

function findByProperty ( propertyName ) {

    return function ( prop ) {
        var length,
            index,
            element;

        length = storage.list.length;

        for ( index = 0; index < length; index++ ) {
            element = storage.list[index];
            if (
                element[propertyName] !== undefined
                && element[propertyName].toString() === prop.toString()
            ) {

                return element;
            }
        }

        return false;
    };
}


storage = {

    list: [],

    findByNumber: findByProperty('number')
};


module.exports = storage;
